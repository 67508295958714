import './App.css';
import React from 'react';
import ProductItemCard from './ProductItemCard';
import { Box } from '@mui/material';
import { ThreeDots } from 'react-loader-spinner'


function App(props) {
  // const liffObject = props.liffObject;
  const userId = props.userId;
  const products = props.products;
  const [isLoading , setIsLoading] = React.useState(false);
  if (isLoading){
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="700px"
      >
        <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#06c755"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
      </Box>
    )
  } else {
    return (
      <div>
        <div className="home">
          <h1 className="home__title">
            もじおこ師匠 <br />
            <div
              className="home__title__link"
            >
              プレミアム
            </div>
          </h1>
          <Box
            align="center"
            sx={{
              width: "100%",
            }}
            >
            {products.map((product) => (
                <ProductItemCard product={product} userId={userId} setIsLoading={setIsLoading}/>
            ))}
          </Box>
        </div>
      </div>
    );
  }
}

export default App;
