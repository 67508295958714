import * as React from 'react';
import { useTheme } from '@mui/material/styles';

import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

// const DEFAULT_IMG_URL = ""
const CHECKOUT_URL = process.env.REACT_APP_CHECKOUT_URL;

const onClickHandler = async (userId, priceId, setIsLoading) => {
  const body = {
    price_id: priceId,
    user_id: userId,
  }
  try {
    setIsLoading(true);
    const res = await fetch(CHECKOUT_URL, {
      method: "POST",
      body: JSON.stringify(body),
    });
    // get checkout url from response
    const { url } = await res.json();
    // redirect to checkout url
    setIsLoading(false);
    window.location.href = url;
  } catch (e) {
    console.error("failed...");
    console.error(e);
  }
}

export default function ProductItemCard(props) {
    const product = props.product;
    const price = props.product.price;
    const userId = props.userId;
    const setIsLoading = props.setIsLoading;
    // const product_img_url = product.images.length > 0 ? product.images[0] : DEFAULT_IMG_URL;
    // const theme = useTheme();

    return (
        <Card sx={{ minWidth: 250, maxWidth: 400, textAlign: "center", m: 1 }}>
            <CardContent>
            <Typography gutterBottom variant="subtitle1" component="div">
                {product.name}
            </Typography>
            <Typography gutterBottom variant="h3" component="div">
                ￥{price.unit_amount}
            </Typography>
            <Button
                size="small"
                sx={{
                    width: "100%",
                    bgcolor: "#06c755",
                    color: "white",
                    p: 1,
                    '&:hover': {
                        background: "#06c755",
                        opacity: [0.9, 0.8, 0.7],
                    },
                }}
                onClick={() => onClickHandler(userId, price.id, setIsLoading)}
            >
                購入する
            </Button>
            </CardContent>
        </Card>
    )
}