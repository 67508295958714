import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import liff from '@line/liff';

const GET_ALL_PRODUCTS_URL = process.env.REACT_APP_GET_ALL_PRODUCTS_URL || '';

const preprocess = async () => {
  const userId = liff.getDecodedIDToken().sub;
  const res = await fetch(GET_ALL_PRODUCTS_URL);
  const json = await res.json();
  const products = json.products;
  return { userId, products };
};
liff
  .init({ liffId: process.env.REACT_APP_LIFF_ID || '' })
  .then(async () => {
    if (!liff.isLoggedIn()) {
      liff.login();
    }
    const { userId, products } = await preprocess();
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <React.StrictMode>
        <App liffObject={liff} userId={userId} products={products}/>
      </React.StrictMode>
    );
  })
  .catch((err) => {
    // need error screen
    console.log(process.env.REACT_APP_LIFF_ID)
  });